<template>
  <div>
    <CardLoadingSkeleton :class="{ 'hidden-display': !loading }" />
    <div class="card-wrapper card" :class="{ 'hidden-visibility': loading }">
      <div
        v-if="title && actionType == CARD_ACTIONS.REDIRECT"
        class="card-header-redirect card-header d-flex justify-content-between align-items-center"
        @click="() => $emit('on-redirect')"
      >
        <p class="fw-bold text-truncate">
          {{ title }}
        </p>
      </div>

      <div
        v-if="title && actionType !== CARD_ACTIONS.REDIRECT"
        class="card-header d-flex justify-content-between align-items-center"
      >
        <p class="fw-bold text-truncate">
          {{ title }}
        </p>

        <FromMonthToMonthStepRangeInput
          v-if="actionType === CARD_ACTIONS.MONTH_STEP_RANGE"
          :steps="steps"
          :from-date="fromDate"
          :to-date="toDate"
          v-on="$listeners"
        />
        <TwoSelectListsDropdownInput
          v-if="actionType === CARD_ACTIONS.TWO_SELECT_LIST_INPUTS"
          :list-one="listOne"
          :list-two="listTwo"
          :should-close-selects-on-scroll="shouldCloseSelectsOnScroll"
          v-on="$listeners"
        />
        <DatePickerInput
          v-if="actionType === CARD_ACTIONS.DATEPICKER"
          :date-picker-placeholder="datePickerPlaceholder"
          :date-picker-label="datePickerLabel"
          :date-filter="dateFilter"
          :date-picker-type="datePickerType"
          :show-date-picker-shortcut-options="showDatePickerShortcutOptions"
          :clearable="clearable"
          v-on="$listeners"
        />
      </div>
      <div v-if="!loading" class="card-body content d-flex justify-content-between">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { CARD_ACTIONS } from '../constants';
import FromMonthToMonthStepRangeInput from './FromMonthToMonthStepRangeInput';
import TwoSelectListsDropdownInput from './TwoSelectListsDropdownInput';
import DatePickerInput from './DatePickerInput';
import { DateTime } from 'luxon';
import { CardLoadingSkeleton } from '@/modules/core/components';

export default {
  components: { FromMonthToMonthStepRangeInput, TwoSelectListsDropdownInput, DatePickerInput, CardLoadingSkeleton },
  props: {
    title: { type: String, default: null },
    actionType: { type: String, default: null },
    steps: { type: Number, default: 6 },
    fromDate: { type: DateTime, default: null },
    toDate: { type: DateTime, default: null },
    listOne: { type: Array, default: null },
    listTwo: { type: Array, default: null },
    datePickerPlaceholder: { type: String, default: null },
    datePickerLabel: { type: String, default: null },
    dateFilter: { type: [String, Date], default: null },
    datePickerType: { type: String, default: 'month' },
    showDatePickerShortcutOptions: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    shouldCloseSelectsOnScroll: { type: Boolean, default: false },
  },
  setup() {
    return {
      CARD_ACTIONS,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/colors.module.scss';
@import '@/stylesheets/scss/typography.module.scss';
.card-wrapper {
  height: 100%;
  .card-header {
    border: 0;
    padding: 0 1.5rem;
    height: 64px;
  }
  .card-header-redirect.card-header {
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }

  .card-body {
    padding: 1.5rem;
    padding-top: 0;
  }

  button {
    border: 0;
  }
  button:hover {
    background-color: #ffffff;
  }
}
.hidden-display {
  display: none;
}

.hidden-visibility {
  visibility: hidden;
}
</style>
